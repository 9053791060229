import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'shards-react';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../localization';
import './orderDetail.css'
import { API_URL, BARCODE_P_LEFT, BARCODE_P_WIDTH, BARCODE_WIDTH, formateNumber, getBarcodeImageHeight, getBarcodeValuesFromResponse, getDataArrayForRestaurant, RESTAURANT } from '../../config';
import Invoice from '../Ventas/Invoice';
import SampleInvoiceForModules from '../restaurant/SampleInvoiceForModules';

function RestaurantOrderDetails(props) {
    console.log('order details props', props)
    const { orderID, closeSideBar } = props;

    // card states
    const [refId, setRefId] = useState(null);
    const [refType, setRefType] = useState(null);
    const [orderId, setOrderId] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [orderTime, setOrderTime] = useState("");
    const [totalAmt, setTotalAmt] = useState("");
    const [foodItems, setFoodItems] = useState([]);
    const [tableName, setTableName] = useState("");
    const [totalPaid, setTotalPaid] = useState("");
    const [returnAmount, setReturnAmount] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDocBtnLoading, setIsDocBtnLoading] = useState(false);

    // propina states
    const [propinaAmount, setPropinaAmount] = useState(0);
    const [propinaChecked, setPropinaChecked] = useState(false);
    const [propinaPercentage, setPropinaPercentage] = useState(0);

    // organisation states
    const [companyLogoForPrint, setCompanyLogoForPrint] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');

    // documents states
    const [printNumber, setPrintNumber] = useState('');
    const [barcodeImage, setBarcodeImage] = useState('');
    const [barcodeHeight, setBarcodeHeight] = useState('');
    const [barcodeWidth, setBarcodeWidth] = useState('');
    const [pLeft, setPLeft] = useState('');
    const [pWidth, setPWidth] = useState('');
    const [showDocumentDetailsModal, setShowDocumentDetailsModal] = useState(false);
    const [docType, setDocType] = useState('');
    const [isReceiptVisible, setIsReceiptVisible] = useState(false);

    // sample invoice states
    const [showPermissionForPropina, setShowPermissionForPropina] = useState(false);
    const [dataArray, setDataArray] = useState([]);

    const handleReceiptButtonPressed = () => {
        const dataArray = getDataArrayForRestaurant(foodItems);
        setDataArray([...dataArray]);
        setIsReceiptVisible(true);
    }

    const handleDocumentButtonPressed = () => {
        setIsDocBtnLoading(true);
        var responseHeight = '';
        var responseWidth = '';
        var pLeft = BARCODE_P_LEFT;
        var pWidth = BARCODE_P_WIDTH;
        let name = refType + 's';

        console.log('name', name)
        console.log('refId', refId)
        fetch(`${API_URL}barcode/${name}/${refId}?responseType=json`, {
            method: 'get',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                return response.json();
            })
            .then(result => {
                console.log('result.......', result);

                if (result.message) {
                    //   swal.fire('', result.message, 'error');
                } else {
                    let barcodeWidth = BARCODE_WIDTH;
                    const data = getBarcodeValuesFromResponse(result);
                    responseHeight = data.responseHeight;
                    responseWidth = data.responseWidth;
                    pLeft = data.pLeft;
                    pWidth = data.pWidth;

                    let barcodeHeight = getBarcodeImageHeight(barcodeWidth, responseHeight, responseWidth);
                    setDocType(name);
                    setBarcodeImage(data.barcodeImage);
                    setBarcodeHeight(barcodeHeight);
                    setBarcodeWidth(barcodeWidth);
                    setPLeft(pLeft);
                    setPWidth(pWidth);
                    setIsDocBtnLoading(false);
                    setShowDocumentDetailsModal(true);
                }
            })
            .catch(err => {
                console.log('catch block error while error getting barcode', err);
                setIsDocBtnLoading(false);
            });
    }

    // This function is used to call restaurant order detail get api
    const getOrderData = async (isInit, argOrderId) => {
        setIsApiLoading(true);
        setIsLoading(true);
        setIsDocBtnLoading(true);
        const id = isInit ? argOrderId : orderId;
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-order/${id}`;
        console.log('apiUrl for getting order detail', apiUrl)
        fetch(apiUrl, {
            method: 'get', headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((response) => {
            console.log('response of single order', response);
            if (statusCode === 200) {
                let data = response['data'];
                console.log('data of order summary api', data);
                if (data) {
                    setOrderNumber(data['orderID']);
                    setOrderDate(data['orderDate']);
                    setOrderTime(data['orderTime']);
                    setFoodItems(data['dataArray']);
                    setTotalAmt(data['netAmount']);
                    setRefType(data['referenceTransactionType']);
                    setRefId(data['referenceTransactionID']);
                    setTableName(data['restaurantTableID']['tableName']);
                    setPropinaAmount(data['propina']['amount']);
                    setPropinaChecked(data['propina']['checked']);
                    setPropinaPercentage(data['propina']['percentage']);
                    setReturnAmount(data['return']);
                    setTotalPaid(data['totalPaid']);
                    setPaymentMode(data['paymentMode']);
                }
            }
            setIsApiLoading(false);
            setIsLoading(false);
            setIsDocBtnLoading(false);
        }).catch((err) => {
            console.log('catch block error while getting order data', err);
            setIsApiLoading(false);
            setIsLoading(false);
            setIsDocBtnLoading(false);
        })
    }

    useEffect(() => {
        let permissions = localStorage.getItem('user_permissions');
        let parse = JSON.parse(permissions);
        let showPermission = parse.enable_propina;
        setShowPermissionForPropina(showPermission);
        if (orderID) {
            setOrderId(orderID);
            getOrderData(true, orderID);
        }
    }, [])

    return (
        <>
            {
                isLoading
                    ? <h4>Loading...</h4>
                    : isReceiptVisible ?
                        <>
                            <SampleInvoiceForModules
                                onBack={() => { }}
                                dataArray={dataArray}
                                calculatorReference={[]}
                                totalPaid={totalPaid}
                                mainPrice={""}
                                tax={undefined}
                                total={formateNumber(totalAmt + propinaAmount, true)}
                                return={returnAmount}
                                paymentMode={paymentMode}
                                totalDiscount={0}
                                type={0}
                                withoutTax={false}
                                propinaAmount={propinaAmount}
                                propinaChecked={propinaChecked}
                                propinaPercentage={propinaPercentage}
                                showPermissionForPropina={showPermissionForPropina}
                                closeAll={() => {
                                    // this.setState({
                                    //     showCustomerScreen: false,
                                    //     isNextButtonClick: false,
                                    //     description: ''
                                    // });
                                    // this.clearAll();
                                }}// todo:  need to add function
                                onBackWhenDirectPrint={() => {
                                    // this.setState({ showCustomerScreen: false, finalPrice: '$0' });
                                }}
                                bypassCustomerScreen={false}
                                transactionDocumentId={""}
                                transactionDocumentType={""}
                                editProductData={[]}
                                editProductFromTransaction={false}
                                moduleName={RESTAURANT}
                                orderId={orderId}
                                showConvertButton={refType ? false : true}
                                closeSideBar={closeSideBar}
                            />
                        </>
                        : <>
                            <Card small className="mb-4">
                                <CardBody className="px-2">
                                    <div className='d-flex align-items-center'>
                                        <div className='tableNameContainer'>
                                            <p className='tableName'>{tableName}</p>
                                        </div>
                                        {/* <p className='orderNo'>{strings.order} {orderNumber}</p> */}
                                        <p className='orderNo'>{strings.order}&nbsp;{orderNumber}</p>
                                    </div>
                                    <div className='d-flex justify-content-between mt-3'>
                                        <p className='orderDate'>{orderDate}</p>
                                        <p className='orderDate'>{orderTime}</p>
                                    </div>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <p className='colText' style={{ textAlign: 'left' }}>Item</p>
                                        </Col>
                                        <Col>
                                            <p className='colText'>Qty</p>
                                        </Col>
                                        <Col>
                                            <p className='colText'>Price</p>
                                        </Col>
                                    </Row>
                                    {
                                        foodItems.length > 0 && foodItems.map((item) => {
                                            return (
                                                <Row className="py-1">
                                                    <Col>
                                                        <p className='rowText' style={{ textAlign: 'left' }}>{item.itemName}</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='rowText'>{item.quantity}</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='rowText'>
                                                            {formateNumber(item.price)}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    <hr />
                                    <div className='d-flex justify-content-between'>
                                        <p className='total'>Total</p>
                                        <p className='total'>{formateNumber(totalAmt)}</p>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className='btnContainer'>
                                <Button
                                    theme="primary"
                                    className="backBtn"
                                    onClick={handleReceiptButtonPressed}
                                >
                                    {
                                        isLoading
                                            ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                            : strings.receipt
                                    }
                                </Button>
                                {
                                    refType &&
                                    <Button
                                        theme="primary"
                                        className="foodAddBtn"
                                        onClick={handleDocumentButtonPressed}
                                    >
                                        {
                                            isDocBtnLoading
                                                ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                                : refType === "invoice" ? strings.invoice : strings.bills
                                        }
                                    </Button>
                                }
                            </div>
                        </>
            }

            {/* Invoice & Bill details dialog section */}
            <Modal
                show={showDocumentDetailsModal}
                onHide={() => setShowDocumentDetailsModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Invoice
                        bill_id={refId}
                        // type={refType}
                        type={docType}
                        view={true}
                        printNumber={printNumber}
                        barcodeImage={barcodeImage}
                        barcodeHeight={barcodeHeight}
                        barcodeWidth={barcodeWidth}
                        pLeft={pLeft}
                        pWidth={pWidth}
                        transaction_view={true}
                        references={[]}
                        showNewButton={false}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RestaurantOrderDetails