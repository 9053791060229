import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'shards-react';
import { strings } from '../../localization';
import PageTitle from '../../components/common/PageTitle';
import { API_URL, formateNumber, getDataArrayForRestaurant, getKOTStatusIcon, RESTAURANT } from '../../config';
import swal from "sweetalert2";
import './Restaurant.css'
import RestaurantFoodItemRow from '../../components/Restaurant/RestaurantFoodItemRow';
import FoodInstructionDialog from '../../components/common/FoodInstructionDialog';
import RestaurantFoodSelection from './RestaurantFoodSelection';
import Calculator from '../Ventas/Calculator';
import KOTReceipt from './KOTReceipt';
import { useReactToPrint } from 'react-to-print';
window.Swal = swal;


// Higher-order component to handle `ref` in class component
const PrintableComponentWithRef = React.forwardRef((props, ref) => (
    <KOTReceipt innerRef={ref} KOTItems={props.KOTItems} tableName={props.tableName} handleBackFromKOTReceipt={props.handleBackFromKOTReceipt} companyLogo={props.companyLogo} organisationInfo={props.organisationInfo} handlePrintKOTComponent={props.handlePrintKOTComponent}/>
  ));


function RestaurantOrderSummary(props) {
    const { id, orderID, refreshTableList, closeSideBar } = props;

    // food items states
    const [orderId, setOrderId] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [grandTotal, setGrandTotal] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [genericNote, setGenericNote] = useState("");
    const [foodItems, setFoodItems] = useState([]);

    // edit food items states
    const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
    const [editFoodDialogItem, setEditFoodDialogItem] = useState('');
    const [editFoodItemIndex, setEditFoodItemIndex] = useState('');
    const [isFoodItemSaveButtonLoading, setIsFoodItemSaveButtonLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlaceOrderButtonLoading, setIsPlaceOrderButtonLoading] = useState(false);
    const [isMarkAsPaidButtonLoading, setIsMarkAsPaidButtonLoading] = useState(false);
    const [draftFoodItems, setDraftFoodItems] = useState([]);

    // alert states
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('danger');

    // food selection states
    const [isFoodSelectionVisible, setIsFoodSelectionVisible] = useState(false);

    // calculator states
    const [isCalculatorVisible, setIsCalculatorVisible] = useState(false);
    const [dataArray, setDataArray] = useState([]);

    // kot print states
    const [isKOTPrintEnabled, setIsKOTPrintEnabled] = useState(false);
    const [companyLogo, setCompanyLogo] = useState('');
    const [organisationInfo, setOrganisationInfo] = useState('');
    const [isKOTReceiptVisible, setIsKOTReceiptVisible] = useState(false)
    const [KOTItems, setKOTItems] = useState([]);
    const [tableName, setTableName] = useState('');
    const KOTPrintRef = useRef(null);

    useEffect(() => {
        getOrganisationData();
        if (orderID) {
            setOrderId(orderID);
            getOrderSummaryData(true, orderID);
        }
    }, []);

   const getOrganisationData = () => {
    let orgInfo = JSON.parse(localStorage.getItem("organisation_info"));
        const orgId = orgInfo['_id'];
        let statuscode = 0;
        setOrganisationInfo(orgInfo.data);
        fetch(`${API_URL}organisations/${orgId}`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statuscode = res.status;
                return res.json();
            })
            .then((res) => {
                console.log('status code of organisation data', statuscode)
                if (statuscode = 200) {
                    let isPrintEnabled = false;
                    if (res['data'] && res['data']['isKotPrint']) {
                        isPrintEnabled = true;
                    }
                    if (res.data) {
                        setCompanyLogo(res.data.companyLogo);
                        setIsKOTPrintEnabled(isPrintEnabled);
                    }
                }
            })
    }

    const CommonBtn = ({ name, onClick, isLoading }) => {
        return (
            <Button
                theme="primary"
                className="mx-1 rounded"
                style={{ height: 40, width: '350px', margin: '5px 0px', }}
                onClick={onClick}
            >
                {
                    isLoading
                        ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                        : name
                }
            </Button>
        )
    }

    const deleteRestaurantFoodItem = (item) => {
        console.log('item', item)
        swal
            .fire({
                title: strings.delete_item,
                text: strings.delete_item_msg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#2E209D",
                cancelButtonColor: "#D61E11",
                confirmButtonText: strings.yes_delete,
                showLoaderOnConfirm: true,
                preConfirm: login => {
                    return fetch(`${API_URL}restaurant-order/${orderId}/item/${item['id']}`, {
                        method: "delete",
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            console.log('response of delete food item api is', res);
                            if (res.status == 200) {
                                setIsLoading(true);
                                getOrderSummaryData();

                                swal.fire(
                                    strings.deleted,
                                    strings.success_item_deleted,
                                    "success"
                                );
                            }
                            return res.json();
                        })
                        .catch(err => {
                            console.log('catch block error', err)
                            swal.showValidationMessage(`Request failed: ${err}`);
                        });
                }
            })
            .then(result => {
                console.log('result of delete', result);
            });
    }

    const editFoodItem = (item, index) => {
        console.log('edit item', item, 'and index is', index)
        setIsEditDialogVisible(true);
        setEditFoodDialogItem(item);
        setEditFoodItemIndex(index);
    }

    const getOrderSummaryData = async (isInitialLoad, orderID) => {
        setIsLoading(true);
        let statusCode = 0;
        let id = orderId;
        if (isInitialLoad) {
            id = orderID;
        }
        const apiUrl = `${API_URL}restaurant-order/${id}`;
        console.log('apiUrl', apiUrl)
        fetch(apiUrl, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((response) => {
            console.log('response of order summary', response);
            if (statusCode === 200) {
                let data = response['data'];
                console.log('data of order summary api', data);
                setIsLoading(false);
                setIsFoodSelectionVisible(false);
                setIsKOTReceiptVisible(false);
                if (data) {
                    let amount = data['netAmount'] ? data['netAmount'] : "";
                    const tableData = data['restaurantTableID']
                    setOrderData(response);
                    setFoodItems(data['dataArray']);
                    setGenericNote(data['genericNote']);
                    setGrandTotal(amount);
                    setIsPaid(data['isPaid']);
                    setTableName(tableData['tableName'] ? tableData['tableName'] : "")
                } else {
                    setFoodItems([]);
                }
            } else {
                setIsLoading(false);
            }
        })
    }

    const handleFoodItemDialogSaveButtonPressed = (item, index) => {
        console.log('updated item', item);
        console.log('updated index', index);
        setIsFoodItemSaveButtonLoading(true);
        orderSummarySingleFoodItemUpdate(item);
    }

    const orderSummarySingleFoodItemUpdate = async (apiData) => {
        console.log('api data for single item update', apiData);
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-order/${orderId}/item/${apiData['id']}`;
        console.log('apiUrl of order summary single update', apiUrl);
        fetch(apiUrl, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(apiData)
        }).then((res) => {
            statusCode = res.status;
            console.log('statuscode of single item update api', res.status);
            return res.json()
        })
            .then((res) => {
                console.log('response of single item update api', res);
                if (statusCode === 200) {
                    setIsEditDialogVisible(false);
                    setIsFoodItemSaveButtonLoading(false);
                    setIsLoading(true);
                } else {
                    const apiMsg = res['message'] ? res['message'] : "Something went wrong!";
                    showAlert(apiMsg, 'danger');
                    setIsEditDialogVisible(false);
                    setIsFoodItemSaveButtonLoading(false);
                }
                getOrderSummaryData();
            })
            .catch((err) => {
                console.log('catch block error at order summary single update api', err);
                setIsEditDialogVisible(false);
                setIsFoodItemSaveButtonLoading(false);
                showAlert("Something went wrong!", 'danger');
            })
    }


    const getDraftStatusItems = () => {
        let draftItems = [];
        if (foodItems.length > 0) {
            let data = foodItems.filter((item) => item['status'] === "draft");
            draftItems = [...data];
        }
        console.log('draft items are', draftItems);
        return draftItems;
    }

    const sendFoodItemsIntoKOT = async () => {
        setIsPlaceOrderButtonLoading(true);
        let statusCode = 0;
        const draftItems = getDraftStatusItems();
        let bodyData = {
            "restaurantTableID": id,
            "restaurantOrderID": orderId,
            "dataArray": draftItems,
            "genericNote": genericNote
        }
        console.log('bodyData of kot post api', bodyData);
        const apiUrl = `${API_URL}restaurant-kot`
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(bodyData)
        }).then((res) => {
            statusCode = res.status;
            console.log('statusCode of kot post api', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of kot post api', res);
            if (statusCode === 200) {
                updateStatusOfOrderFoodItems(res['_id'], draftItems);
            } else {
                const errorMsg = res['message'] ? res['message'] : "Something went wrong!";
                showAlert(errorMsg, 'danger');
                setIsPlaceOrderButtonLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at kot post api', err);
            setIsPlaceOrderButtonLoading(false);
        })
    }

    const handlePrintKOTComponent = useReactToPrint({content: ( ) => KOTPrintRef.current});

    const updateStatusOfOrderFoodItems = async (kotId = "", draftItems = []) => {
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-order/${orderId}?status=prepared`;
        console.log('order status update api url', apiUrl);
        let bodyData = {
            "kotID": kotId
        }
        console.log('bodyData of update status', bodyData);
        fetch(apiUrl, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(bodyData)
        }).then((res) => {
            statusCode = res.status;
            console.log('statuscode of order status update api', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of order status update api', res);
            if (statusCode === 200) {
                setIsPlaceOrderButtonLoading(false);
                if (isKOTPrintEnabled) {
                    setKOTItems(draftItems);
                    setIsKOTReceiptVisible(true)
                }
                swal
                    .fire({
                        text: strings.order_placed_successfully,
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#2E209D",
                        cancelButtonColor: "#D61E11",
                        confirmButtonText: strings.continue,
                        showLoaderOnConfirm: true,
                    })
                    .then(result => {
                        console.log('result of delete', result);
                            setIsKOTReceiptVisible(false);
                            refreshTableList();
                            getOrderSummaryData();
                    });
            } else {
                const errorMsg = res['message'] ? res['message'] : "Something went wrong!";
                showAlert(errorMsg, 'danger');
                setIsPlaceOrderButtonLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at order status update api', err);
            setIsPlaceOrderButtonLoading(false);
        })
    }

    const handleMarkAsPaid = () => {
        if (!hasAnyDraftStatusInOrder()) {
            swal
                .fire({
                    // title: strings.delete_item,
                    text: strings.mark_as_paid_msg,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#2E209D",
                    cancelButtonColor: "#D61E11",
                    confirmButtonText: strings.yes_mark_as_paid,
                    showLoaderOnConfirm: true,
                    preConfirm: login => {
                        navigateToPaymentScreen();
                        // return fetch(`${API_URL}restaurant-order/${orderId}`, {
                        //     method: 'put',
                        //     credentials: "include",
                        //     headers: {
                        //         Accept: "application/json",
                        //         "Content-Type": "application/json"
                        //     },
                        //     body: JSON.stringify({
                        //         'isPaid': true,
                        //         "dataArray": []
                        //     })
                        // })
                        //     .then(res => {
                        //         console.log('response of mark as paid api is', res);
                        //         if (res.status == 200) {
                        //             navigateToPaymentScreen();

                        //             swal.fire(
                        //                 strings.mark_as_paid_success_title,
                        //                 strings.mark_as_paid_success_msg,
                        //                 "success"
                        //             );
                        //         }
                        //         return res.json();
                        //     })
                        //     .catch(err => {
                        //         console.log('catch block error', err)
                        //         swal.showValidationMessage(`Request failed: ${err}`);
                        //     });
                    }
                })
                .then(result => {
                    console.log('result of delete', result);
                });
        } else {
            showAlert(strings.mark_as_paid_warning, 'danger');
        }
    }

    const navigateToPaymentScreen = () => {
        // let dataArray = [];

        // const mergedFoodItems = Object.values(foodItems.reduce((acc, curr) => {
        //     if (!acc[curr.itemID]) {
        //         acc[curr.itemID] = { ...curr };
        //     } else {
        //         acc[curr.itemID].quantity = String(Number(acc[curr.itemID].quantity) + Number(curr.quantity));
        //         acc[curr.itemID].totalPrice = Number(acc[curr.itemID].totalPrice) + Number(curr.totalPrice);
        //     }
        //     return acc;
        // }, {}));

        // console.log('mergedDuplicate food items', mergedFoodItems)

        // const formattedFoodItems = mergedFoodItems.map(({ itemCode, itemID, itemName, price, quantity, totalPrice }) => ({
        //     itemCode,
        //     itemID,
        //     itemName,
        //     price,
        //     quantity,
        //     totalPrice
        // }));

        // console.log('formatted food items', formattedFoodItems);


        // formattedFoodItems.map((item, index) => {

        //     let singleObj = {
        //         "FAWT": item.totalPrice ? item.totalPrice : 0,
        //         "discount": 0,
        //         "iva": 0,
        //         "mainPriceWithTax": item.price ? item.price : 0,
        //         "percent": true,
        //         "tax": true,
        //         "totalDiscount": 0,
        //         "itemType": "",

        //         "itemCode": item.itemCode ? item.itemCode : "",
        //         "description": item.itemName ? item.itemName : "",
        //         "quantity": item.quantity ? item.quantity : 0,
        //         "total": item.totalPrice ? item.totalPrice : 0,
        //         "mainPrice": item.price ? item.mainPrice : 0,
        //     }
        //     dataArray.push(singleObj);
        // })


        const dataArray = getDataArrayForRestaurant(foodItems);

        // let params = {
        //     orderId: orderId,
        //     data: dataArray,
        //     total: formateNumber(grandTotal, true),
        //     totalDiscount: 0,
        //     type: 0,
        //     withoutTax: false,
        //     propinaChecked: false,
        //     editProductFromTransaction: false,
        //     editProductData: [],
        //     "moduleName": RESTAURANT
        // }

        // console.log('params which passed in navigation', params);
        // TODO: navigation is remaining to do
        setDataArray(dataArray);
        setIsCalculatorVisible(true)
    }

    const showAlert = (msg, type) => {
        setIsAlertVisible(true);
        setAlertType(type);
        setAlertMsg(msg);

        setTimeout(() => {
            setIsAlertVisible(false);
        }, 4000)
    }
    const hasAnyDraftStatusInOrder = () => {
        if (foodItems.length > 0) {
            const draftIndex = foodItems.findIndex((item) => item['status'] === "draft");
            if (draftIndex !== -1) {
                return true;
            }
            return false;
        }
        return false;
    }

    const hasAnyNonDraftStatusInOrder = () => {
        if (foodItems.length > 0) {
            const draftIndex = foodItems.findIndex((item) => item['status'] !== "draft");
            console.log('draftIndex', draftIndex);
            if (draftIndex !== -1) {
                return true;
            }
            return false;
        }
        return false;
    }

    // This function is used when increase or decrease item quantity
    const manageFoodQuantity = (foodItem, index, isFromIncrement) => {
        const foodStatus = foodItem['status'];
        const isDraft = foodStatus && foodStatus === "draft";
        let isItemDeletable = true;

        const oldFoodItems = [...foodItems];
        let foodItemQty = parseFloat(oldFoodItems[index]['quantity']);
        if (isFromIncrement) {
            foodItemQty++;
            oldFoodItems[index]['quantity'] = foodItemQty.toString();
            setIsFoodItemSaveButtonLoading(true);
            orderSummarySingleFoodItemUpdate(oldFoodItems[index]);
        } else {
            if (foodItemQty === 1) {
                deleteRestaurantFoodItem(oldFoodItems[index]);
            } else {
                foodItemQty--;
                oldFoodItems[index]['quantity'] = foodItemQty.toString();
                setIsFoodItemSaveButtonLoading(true);
                orderSummarySingleFoodItemUpdate(oldFoodItems[index]);
            }
        }
    }

    const handleBackButton = (isDataAdded, orderId) => {
        if (isDataAdded) {
            getOrderSummaryData(true, orderId);
            refreshTableList();
            setOrderId(orderId);
        } else {
            setIsFoodSelectionVisible(false);
        }
    }

    const handleBackFromCalculator = () => {
        setIsCalculatorVisible(false);
        setIsFoodSelectionVisible(false);
    }

    const handleBackFromKOTReceipt = () => {
        refreshTableList();
        getOrderSummaryData();
        setIsCalculatorVisible(false);
        setIsFoodSelectionVisible(false);
        setIsKOTReceiptVisible(false);
    }

    return (
        <>
            {
                isCalculatorVisible
                    ? <Calculator
                        type={0}
                        transactionDocumentId={""}
                        transactionDocumentType={""}
                        editProductFromTransaction={false}
                        editProductData={[]}
                        moduleName={RESTAURANT}
                        dataArray={dataArray}
                        total={formateNumber(grandTotal, true)}
                        orderId={orderId}
                        backToOrderSummary={handleBackFromCalculator}
                        closeSideBar={closeSideBar}
                    />
                    :
                    isFoodSelectionVisible
                        ? <RestaurantFoodSelection
                            id={id}
                            orderId={orderId}
                            draftFoodItems={draftFoodItems}
                            handleBackButton={handleBackButton}
                        />
                        :
                        <div>
                            <p className='mt-10' style={{ fontSize: 20 }}>{strings.order_summary}</p>
                            {
                                isLoading
                                    ? <h4>Loading...</h4>
                                    : <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 50,
                                        marginBottom: 50
                                    }}>
                                        {
                                            orderId
                                                ? <div style={{ width: '100%' }}>
                                                    {
                                                        foodItems.length > 0
                                                            ? foodItems.map((item, index) => {
                                                                const foodStatus = item['status'];
                                                                const isDraft = foodStatus && foodStatus === "draft";
                                                                const commonOpacity = isDraft ? 1 : 0.5;
                                                                return (
                                                                    <RestaurantFoodItemRow
                                                                        foodItem={item}
                                                                        foodIndex={index}
                                                                        handleEditBtnPressed={editFoodItem}
                                                                        manageFoodQuantity={manageFoodQuantity}
                                                                        isFromSummary={true}
                                                                    />
                                                                )
                                                            })
                                                            : null
                                                    }
                                                    <div className='hrTag' />
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className='orderSummaryCommonTitle'>{strings.grand_total}</p>
                                                        <p className='orderSummaryCommonTitle'>{grandTotal ? formateNumber(grandTotal) : ""}</p>
                                                    </div>
                                                </div>
                                                : <div>
                                                    <img
                                                        src={require('../../images/empty_order.png')}
                                                    />
                                                    <h5 style={{ textAlign: 'center', marginTop: 20 }}>{strings.no_food_added}</h5>
                                                </div>
                                        }
                                        <div style={{ marginTop: 50 }}>
                                            <CommonBtn
                                                name={orderId ? strings.add_more_food : strings.add_food}
                                                onClick={() => {
                                                    const draftItems = getDraftStatusItems();
                                                    console.log('draftItems', draftItems);
                                                    setDraftFoodItems(draftItems);
                                                    setIsFoodSelectionVisible(true);
                                                }}
                                                isLoading={false}
                                            />

                                            {
                                                orderId && !isPaid &&
                                                <>
                                                    {
                                                        hasAnyDraftStatusInOrder() &&
                                                        <CommonBtn
                                                            name={strings.place_order}
                                                            onClick={sendFoodItemsIntoKOT}
                                                            isLoading={isPlaceOrderButtonLoading}
                                                        />
                                                    }
                                                    {
                                                        hasAnyNonDraftStatusInOrder() &&
                                                        <CommonBtn
                                                            name={strings.mark_as_paid}
                                                            onClick={handleMarkAsPaid}
                                                            isLoading={isMarkAsPaidButtonLoading}
                                                        />
                                                    }
                                                </>
                                            }
                                        </div>

                                        {isKOTReceiptVisible &&
                                            <PrintableComponentWithRef ref={KOTPrintRef} KOTItems={KOTItems} tableName={tableName} handleBackFromKOTReceipt={handleBackFromKOTReceipt} companyLogo={companyLogo} organisationInfo={organisationInfo} handlePrintKOTComponent={handlePrintKOTComponent}/>
                                          }
                                    </div>
                            }
                            {
                                isEditDialogVisible &&
                                <FoodInstructionDialog
                                    showModal={isEditDialogVisible}
                                    setShowModal={setIsEditDialogVisible}
                                    foodItem={editFoodDialogItem}
                                    foodIndex={editFoodItemIndex}
                                    onSaveButtonPressed={handleFoodItemDialogSaveButtonPressed}
                                    isSaveButtonLoading={isFoodItemSaveButtonLoading}
                                    showSaveButton
                                />
                            }
                            <Alert
                                open={isAlertVisible}
                                theme={alertType}
                                style={{
                                    position: 'fixed',
                                    bottom: 10,
                                    // width: '80%',
                                }}
                            >
                                {alertMsg}
                            </Alert>
                        </div>

            }
        </>
    )
}

export default RestaurantOrderSummary