import React, { useEffect, useState } from 'react'
import ReactDatatable from "@ashvin27/react-datatable";
import { API_URL, getKOTStatusIcon, LIMIT, RESTAURANT_ADMIN } from '../../config';
import swal from "sweetalert2";
import { strings } from '../../localization';
import { Button, FormCheckbox } from 'shards-react';
import FoodInstructionDialog from '../../components/common/FoodInstructionDialog';
window.Swal = swal;

function KOTOrderList(props) {

    // const { loading, records, limit, config, handleStatus, isCurrentOrders } = props;

    const { loading, records, limit, config, handleStatus, isCurrentOrders, next, prev, handlePageIncrement, handlePageDecrement } = props;

    const [columns, setColumns] = useState([]);
    const [isNoteDialogVisible, setIsNoteDialogVisible] = useState(false);
    const [editFoodDialogItem, setEditFoodDialogItem] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isKOTPrintEnabled, setIsKOTPrintEnabled] = useState(false);
    const [restaurantUserRole, setRestaurantUserRole] = useState('');

    const getBtnTitle = (status) => {
        let btnName = "";
        if (status === "in_queue") {
            btnName = strings.accept;
        } else if (status === "cooking") {
            btnName = strings.complete;
        }
        return btnName;
    }

    useEffect(() => {
        let displayCol = [
            {
                key: "itemName",
                text: strings.food_name,
                cell: record => {
                    return (
                        <div className='d-flex align-items-end'>
                            <p style={{ marginRight: '3px' }}>{record.itemName}</p>
                            {getKOTStatusIcon(record.status, 18, 'black')}
                        </div>
                    )
                }
            },
            {
                key: "tableName",
                text: strings.table_name,
            },
            {
                key: "quantity",
                text: strings.food_quantity,
                cell: record => {
                    return (
                        <p className="mx-2 my-2 text-capitalize" style={{ fontSize: '11px' }}>x{record.quantity}</p>
                    )
                }
            },
            {
                key: "",
                text: strings.note,
                cell: record => {
                    const note = record['note'];
                    return (
                        <div className='d-flex'>
                            <button
                                disabled={note && note.length > 0 ? false : true}
                                onClick={() => {
                                    setEditFoodDialogItem(record);
                                    setIsNoteDialogVisible(true);
                                }}
                                style={{ border: 0, backgroundColor: 'transparent' }}
                            >
                                {
                                    note && note.length > 0
                                        ?
                                        <i class="material-icons" style={{ fontSize: 22, color: '#2E1F9D', fontWeight: 400 }}>
                                            note
                                        </i>
                                        :
                                        <i class="material-icons-outlined" style={{ fontSize: 22, color: 'black', fontWeight: 400 }}>
                                            note
                                        </i>
                                }
                            </button>
                        </div>
                    )
                }
            },
        ]

        if (isCurrentOrders) {
            displayCol.push({
                key: '',
                text: 'Acción',
                cell: record => {
                    const kotID = record['kotID'];
                    const itemStatus = record['status'];
                    const itemID = record['id'];
                    return (
                        <div className='mt-2'>
                            <Button
                                theme="primary"
                                className="mb-2 mx-2"
                                style={{ width: '100px' }}
                                onClick={() => {
                                    if (itemStatus === "in_queue") {
                                        handleStatus(kotID, itemID, false);
                                    } else {
                                        handleStatus(kotID, itemID, true);
                                    }
                                }}
                            >
                                {getBtnTitle(itemStatus)}
                            </Button>
                        </div>
                    )
                }
            })
        }
        setColumns([...displayCol])
        setIsLoading(false);
    }, [])

    const onKOTPrintValueChange = async () => {
        let orgInfo = JSON.parse(localStorage.getItem("organisation_info"));
        const orgId = orgInfo['_id'];
        const orgData = orgInfo['data'];
        orgData['isKotPrint'] = !isKOTPrintEnabled;
        let statusCode = 0;
            const api = `${API_URL}organisations/${orgId}`;
            fetch(api, {
                method: 'put',
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: 'include',
                body: JSON.stringify(orgData)
            }).then((res) => {
                statusCode = res.status;
                return res.json();
            }).then((res) => {
                console.log('response of organisation update api', res)
                if (statusCode === 200) {
                    setIsKOTPrintEnabled(!isKOTPrintEnabled);
                }
            }).catch((err) => {
                console.log("🚀 ~ API_URL ~ err:", err)
            })
    }

    const getOrganisationData = async () => {
        const parsedUserInfo = JSON.parse(localStorage.getItem('user_info'));
        let restaurantUserRoleValue = "";
        if (
            parsedUserInfo && parsedUserInfo['data'] && parsedUserInfo['data']['restaurantUserRole']
        ) {
            restaurantUserRoleValue = parsedUserInfo['data']['restaurantUserRole']
        }
        setRestaurantUserRole(restaurantUserRoleValue)
        if (restaurantUserRoleValue === RESTAURANT_ADMIN) {
            let orgInfo =  JSON.parse(localStorage.getItem("organisation_info"));
            const orgId = orgInfo['_id'];
            let statusCode = 0
                fetch(`${API_URL}organisations/${orgId}`, {
                    method: 'get',
                    credentials: 'include'
                }).then((res) => {
                    statusCode = res.status
                    return res.json()
                }).then((res) => {
                    if (statusCode === 200) {
                        let isPrintEnabled = false;
                        if (res['data'] && res['data']['isKotPrint']) {
                            isPrintEnabled = true;
                        }
                        setIsKOTPrintEnabled(isPrintEnabled);
                    }
                }).catch((err) => {
                    console.log('catch block error occurs while call org get api', err)
                })
        }
    }

    useEffect(() => {
        getOrganisationData();
    }, [])


    return (
        <>
            {
                isLoading
                    ? <p>loading..</p>
                    : <>
                        {isNoteDialogVisible &&
                            <FoodInstructionDialog
                                showModal={isNoteDialogVisible}
                                setShowModal={setIsNoteDialogVisible}
                                foodItem={editFoodDialogItem}
                                foodIndex={0}
                                onSaveButtonPressed={(itemData, index) => { }}
                                isSaveButtonLoading={false}
                                showSaveButton={false}
                            />
                        }
                        <div className="MainDiv">
                        {
                                    isCurrentOrders && restaurantUserRole === RESTAURANT_ADMIN &&
                                    <div style={{display:'flex', justifyContent:'flex-end', width: 'auto'}}>
                                        <div className="d-flex align-items-center justify-content-end w-50">
                                        <label className="form-check-label me-3" htmlFor="flexSwitchCheck">
                                            {strings.print}
                                        </label>
                                        <div className="form-check form-switch">
                                            <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheck"
                                            style={{
                                                    width: "3rem",
                                                    height: "1.5rem",
                                                    transform: "scale(0.8)", // Increase size
                                                    cursor: "pointer",
                                            }}
                                            checked={isKOTPrintEnabled}
                                            // onChange={() => setIsKOTPrintEnabled(!isKOTPrintEnabled)}
                                            onChange={() => onKOTPrintValueChange()}
                                            />
                                        </div>
                                    </div>
                                    </div>
                                }
                            <div className="table-responsive" style={{ overflowY: "auto", maxHeight: '50vh', marginTop: isCurrentOrders ? '20px' : 0 }}>
                                <ReactDatatable
                                    config={config}
                                    records={records}
                                    columns={columns}
                                    loading={loading}
                                />
                                {
                                    !isCurrentOrders &&
                                    <nav className="d-flex justify-content-center align-items-center">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageDecrement}
                                                    style={
                                                        prev
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Anterior
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageIncrement}
                                                    style={
                                                        next
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Próxima
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default KOTOrderList