import React from 'react'
import { formateNumber, getKOTStatusIcon } from '../../config'
import './RestaurantFoodItemRow.css';

function RestaurantFoodItemRow(props) {
    const { foodItem, foodIndex, handleEditBtnPressed, manageFoodQuantity, isFromSummary } = props;


    const foodItemData = isFromSummary ? foodItem : foodItem['data'];

    const foodStatus = foodItemData['status'];
    const foodKOTStatus = foodItem['kotStatus'];
    const isKOTDraft = foodKOTStatus && foodKOTStatus === "in_queue";
    const isFoodItemEditable = ((foodStatus && foodStatus === "draft") ||  isKOTDraft)
    const isDraft = isFromSummary ? isFoodItemEditable : true;
    const commonOpacity = isDraft ? 1 : 0.5;

    const getTotalPrice = () => {
        const price = foodItemData['price'];
        const qty = foodItemData['quantity'];
        let totalPrice = price;
        if (qty && qty !== '0') {
            totalPrice = price * qty
        }

        return totalPrice;
    }

    return (
        <>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                opacity: commonOpacity
            }}>
                <div style={{
                    width: '40%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap'
                }}>
                    <p className='restaurantFoodName' >{isFromSummary ? foodItemData['itemName'] : foodItemData['name']}</p>
                    {isFromSummary && foodItemData['kotStatus'] && getKOTStatusIcon(foodItemData['kotStatus'], 18, 'black')}
                </div>
                <div style={{
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    {
                        foodItemData['quantity'] && foodItemData['quantity'] !== '0' &&
                        <>
                            <button disabled={!isDraft} onClick={() => handleEditBtnPressed(foodItem, foodIndex)} style={{ backgroundColor: 'transparent', borderColor: 'white', boxShadow: 'none', border: 0, width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="material-icons" style={{ fontSize: 20, color: 'black', marginLeft: 5, }}>
                                    edit
                                </i>
                            </button>
                            <button disabled={!isDraft} onClick={() => manageFoodQuantity(foodItem, foodIndex, false)} style={{ backgroundColor: 'white', borderColor: 'white', boxShadow: 'none', border: 0, width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="material-icons" style={{ fontSize: 20, color: 'black', marginRight: 5, }}>
                                    remove_circle_outline
                                </i>
                            </button>
                            <p className='orderSummaryCommonTitle'>{foodItemData['quantity']}</p>
                        </>
                    }
                    <p className='orderSummaryCommonTitle' style={{ marginRight: 5, marginLeft: 10 }}>{foodItemData['price'] ? formateNumber(getTotalPrice()) : ''}</p>
                    <button disabled={!isDraft} onClick={() => manageFoodQuantity(foodItem, foodIndex, true)} style={{ backgroundColor: 'white', borderColor: 'white', boxShadow: 'none', border: 0, width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <i className="material-icons" style={{ fontSize: 20, color: 'black', fontWeight: 400, }}>
                            add_circle_outline
                        </i>
                    </button>
                </div>
            </div>
            <hr />
        </>
    )
}

export default RestaurantFoodItemRow