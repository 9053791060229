import React, { Component } from 'react';
import { strings } from "../../localization";
import { Language} from "../../config";
import writtenNumber from 'written-number';
import {  Button } from "shards-react";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

export class KOTReceipt extends Component {
    constructor(props) {
        super(props);
        strings.setLanguage(Language);
        this.componentRef = React.createRef(null);
        this.state = {
            date: '',
            time: '',
            KOTItems: [],
            rutNumber: '',
            organisation_info: [],
            companyLogo: '',
            orderId: "",
            tableName: ""
        };
    }

    updateData = async () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let time = today.toLocaleTimeString();
        today = dd + '/' + mm + '/' + yyyy;

        const param = this.props;
        console.log('*******', param);
        if (param !== undefined) {
                this.setState(
                    {
                        KOTItems: param.KOTItems,
                        date: today,
                        time: time,
                        rutNumber: param.rutNumber,
                        orderId: param.orderId,
                        tableName: param.tableName,
                        companyLogo: param.companyLogo,
                        organisation_info: param.organisationInfo
                    },
                    () => {
                        if (this.props.handlePrintKOTComponent) {
                            this.props.handlePrintKOTComponent();
                        }
                    }
                );
                // param.dataArray = undefined;
        }
    };


    async componentDidMount() {
        this.updateData()
        writtenNumber.defaults.lang = 'es';
    }

    render() {
        return (
            <div style={{display: 'none'}}>
             <>
                    <div

                    >
                        <div
                            ref={this.props.innerRef}
                            className="sample-invoices"
                            style={{
                                backgroundColor: '#fff',
                                width: '100%',
                                borderColor: '#999999',
                                borderWidth: 1,
                                padding: 20
                            }}>

                            <div>
                                <p style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    opacity: 0.7,
                                }} >
                                    -----------------------------------------------
                                </p>
                                <p style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    opacity: 0.7,
                                }}>
                                    R.U.T. :{' '}
                                    {this.state.organisation_info.hasOwnProperty('rutNumber')
                                        ? this.state.organisation_info['rutNumber']
                                        : null}
                                </p>
                                {this.state.date && this.state.time ? (
                                    <p style={{
                                        textAlign: 'center',
                                        fontSize: 15,
                                        opacity: 0.7,
                                    }}>
                                        Fecha : {this.state.date} {this.state.time}
                                    </p>
                                ) : null}
                                <p style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    opacity: 0.7,
                                }} >
                                    -----------------------------------------------
                                </p>
                            </div>

                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '10px'
                                }}
                            >
                                {
                                    this.state.companyLogo !== undefined
                                    && this.state.companyLogo !== ''
                                    && <img
                                        style={{
                                            width: "auto",
                                            height: "100px",
                                        }}
                                        src={this.state.companyLogo}
                                        alt="EBOL"
                                    />
                                }
                            </div>

                            {
                                this.state.KOTItems.map((singleItemRowData) => {
                                    return (
                                        <>
                                            <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                                                {singleItemRowData.itemName} X{' '}{singleItemRowData.quantity}
                                            </p>
                                            <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                                                {this.state.tableName}
                                            </p>
                                            {singleItemRowData.note && singleItemRowData.note.length > 1 &&
                                                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                                                    {singleItemRowData.note}
                                                </p>
                                            }
                                            <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, marginTop: '5px' }}>
                                                {' '}
                                            </p>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                    <Button pill size='lg' className='mt-4' style={{
                                        width: '40%', alignSelf: 'center'
                                    }}
                                        onClick={() => this.props.handleBackFromKOTReceipt()}
                                    >{strings.go_back}</Button>
                        <ReactToPrint content={() => this.componentRef.current}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button pill size='lg' className='mt-4' style={{
                                        width: '40%', alignSelf: 'center'
                                    }}
                                        onClick={handlePrint}
                                    >{strings.print}</Button>

                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                    </div>
                </>
            </div>
        )
    }
}

export default KOTReceipt;